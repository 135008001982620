import React, { Component } from 'react'

import {
    Grid,
    Segment,
    Responsive 
  } from 'semantic-ui-react'

export class GoogleMap extends Component {
    render() {
        return (

            <Segment vertical style={{ padding: '0', margin: '0'}}>
            <Grid verticalAlign='middle' centered>
              <Grid.Row>
                <Grid.Column width={16} textAlign='center'>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d92406.63875684368!2d-79.59934256722457!3d43.63344731969852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b37c79e7b9ec1%3A0x822eedb5264a8a40!2s21%20Advance%20Rd%2C%20Etobicoke%2C%20ON%20M8Z%202S6!5e0!3m2!1sen!2sca!4v1576533049417!5m2!1sen!2sca" width="100%" height="400px" frameBorder="0" style={{ border: 0,padding: '0',  margin: '0'}} allowfullscreen=""></iframe>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

        )
    }
}

export default GoogleMap
