import React, { Component } from 'react';

import {
    Grid,
    Header,
    Segment,
    Responsive
  } from "semantic-ui-react";

export class Headings extends Component {
    render() {
        return (
            <Segment style={{ padding: '0em' }} vertical id="service">

            <Responsive as={Grid} celled='internally' columns='equal' stackable minWidth={761}>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Header as='h3' style={{ fontSize: '2em' }}>
                  Always Reliable
                  </Header>
                  <p style={{ fontSize: '1.33em'}}>
                  Our experienced technicians making sure your car is running safe
                and smoothly.
                      </p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Header as='h3' style={{ fontSize: '2em' }}>
                  Wide Range of Services
                  </Header>
                  <p style={{ fontSize: '1.33em' }}>
                From car maintenance to automotive repairs, we can take
                care of all your car needs.
                  </p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Header as='h3' style={{ fontSize: '2em' }}>
                  30 Years in The Business
                  </Header>
                  <p style={{ fontSize: '1.33em' }}>
                  Since 1990, TACH Automotive has been serving the Etobicoke
                community with fantastic customer service.
                      </p>
                </Grid.Column>
              </Grid.Row>
            </Responsive>

            <Responsive as={Grid} celled='internally' columns='equal' stackable maxWidth={760}>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Header as='h3' style={{ fontSize: '1.5rem' }}>
                  Always Reliable
                  </Header>
                  <p style={{ fontSize: '1rem' }}>
                  Our experienced technicians making sure your car is running safe
                and smoothly.
                      </p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Header as='h3' style={{ fontSize: '1.5rem' }}>
                  Wide Range of Services
                  </Header>
                  <p style={{ fontSize: '1rem' }}>
                From car maintenance to automotive repairs, we can take
                care of all your car needs.
                  </p>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                  <Header as='h3' style={{ fontSize: '1.5rem' }}>
                  30 Years in The Business
                  </Header>
                  <p style={{ fontSize: '1rem' }}>
                  Since 1990, TACH Automotive has been serving the Etobicoke
                community with fantastic customer service.
                      </p>
                </Grid.Column>
              </Grid.Row>
            </Responsive>
          </Segment>
        )
    }
}

export default Headings

