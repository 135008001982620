import React, { Component } from 'react';

import {
    Container,
    Segment
  } from "semantic-ui-react";

export class Footer extends Component {
    render() {
      const d = new Date();
      const date = d.getFullYear();
        return (
            <Segment vertical style={{ backgroundColor: '#1769aa', padding: "2em 0em" }}>
            <Container>
              <div style={{ textAlign: "center", color: "#fff" }}>
                21 Advance Rd, Etobicoke, ON M8Z 2S6
                <br />
                Tel: (416) 231-9195 | Fax: (416) 231-4763 <br />
                TACH Automotive Ltd. &copy;{date}
              </div>
            </Container>
          </Segment>
        )
    }
}

export default Footer
