import React, { Component } from 'react';

import {
    Grid,
    Header,
    Segment,
    Responsive
  } from "semantic-ui-react";

export class ContactHour extends Component {
    render() {
        return (
            <Segment style={{ padding: '0em' }} vertical id="contact">
            <Responsive minWidth={761} as={Grid} celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                <Header as="h3" style={{ fontSize: "2em" }}>
                Contact
              </Header>
              <table style={{ margin: "auto" }}>
                <tr>
                  <td style={{ width: "33%" }}>Tel:</td>
                  <td>(416) 231-9195</td>
                </tr>
                <tr>
                  <td>Fax:</td>
                  <td>(416) 231-4763</td>
                </tr>
              </table>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                <Header as="h3" style={{ fontSize: "2em" }}>
                Business Hours
              </Header>
              <table style={{ margin: "auto", textAlign: 'left' }}>
                <tr>
                  <td style={{ width: "60%" }}>Monday - Friday</td>
                  <td>9:00AM - 6:00PM</td>
                </tr>
                <tr>
                  <td>Saturday</td>
                  <td>9:00AM - 3:00PM</td>
                </tr>
                <tr>
                  <td>Sunday &amp; Holidays</td>
                  <td>Closed</td>
                </tr>
              </table>
                </Grid.Column>
              </Grid.Row>
            </Responsive>

            <Responsive maxWidth={760} as={Grid} celled='internally' columns='equal' stackable>
              <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '4em', paddingTop: '4em' }}>
                <Header as="h3" style={{ fontSize: "1.5em" }}>
                Contact
              </Header>
              <table style={{ margin: "auto" }}>
                <tr>
                  <td style={{ width: "33%" }}>Tel:</td>
                  <td>(416) 231-9195</td>
                </tr>
                <tr>
                  <td>Fax:</td>
                  <td>(416) 231-4763</td>
                </tr>
              </table>
                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '4em', paddingTop: '4em' }}>
                <Header as="h3" style={{ fontSize: "1.5em" }}>
                Business Hours
              </Header>
              <table style={{ margin: "auto", textAlign: 'left'}}>
                <tr>
                  <td style={{ width: "50%"}}>Monday - Friday</td>
                  <td style={{textAlign: 'right'}}>9:00AM - 6:00PM</td>
                </tr>
                <tr>
                  <td>Saturday</td>
                  <td style={{textAlign: 'right'}}>9:00AM - 3:00PM</td>
                </tr>
                <tr>
                  <td>Sunday &amp; Holidays</td>
                  <td style={{textAlign: 'right'}}>Closed</td>
                </tr>
              </table>
                </Grid.Column>
              </Grid.Row>
            </Responsive>
          </Segment>
        )
    }
}

export default ContactHour
