import React, { Component } from 'react';

import {
    Grid,
    Header,
    Segment,
    Responsive
  } from "semantic-ui-react";

export class Location extends Component {
    render() {
        return (
          <div>
          <Responsive as={Segment}
          id="location"
          minWidth={761}
          style={{ backgroundColor: "#eee", padding: "7rem 0", margin: "0", borderRadius: '0' }}
        >
          <Grid verticalAlign="middle" centered>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <Header as="h1" style={{ fontSize: "2em" }}>
                  Location
                </Header>
                <p style={{ fontSize: "1.33em" }}>
                  21 Advance Rd, Etobicoke, ON, M8Z 2S6
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>

        <Responsive as={Segment}
          id="location"
          maxWidth={760}
          style={{ backgroundColor: "#eee", padding: "4rem 0", margin: "0", borderRadius: '0' }}
        >
          <Grid verticalAlign="middle" centered>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <Header as="h1" style={{ fontSize: "1.5em" }}>
                  Location
                </Header>
                <p style={{ fontSize: "1em" }}>
                  21 Advance Rd, Etobicoke, ON, M8Z 2S6
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
        </div>
        )
    }
}

export default Location
