import React, { Component } from "react";

import Slider from 'react-slick';

import { Grid, Header, Segment, Card, Responsive, Image } from "semantic-ui-react";

export class Review extends Component {
  state = {
    reviews: [{}]
  };

  componentDidMount() {
    const google = window.google;

    const callback = (place, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        let reviews = [];
        place.reviews.map(reviewer => {
          reviews.push(reviewer);
        });
        this.setState({
          reviews
        });
      }
    };

    var map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: -33.866, lng: 151.196 },
      zoom: 15
    });

    var request = {
      placeId: "ChIJ4cfpd8c3K4gRec5TZXUl1Hk",
      fields: ["reviews"]
    };

    let service = new google.maps.places.PlacesService(map);
    service.getDetails(request, callback);
  }

  render() {

    const settings = {
        dots: true
      };

    const cardReview = this.state.reviews
      .filter(card => card.rating > 4)
      .map(card => (
        <div>
        <Responsive as={Card}
            minWidth={761}
          style={{
            width: "500px",
            padding: "0rem 0 1rem 0",
            boxShadow: "none"
          }}
          centered
        >
          <Card.Content>
            <Image
              as="a"
              href={card.author_url}
              target="_blank"
              size="mini"
              src={card.profile_photo_url}
              style={{ marginBottom: "1rem" }}
            />
            <Card.Header>
              <a href={card.author_url} target="_blank" style={{color: '#e53935'}}>
                {card.author_name}
              </a>
            </Card.Header>
            <Card.Meta>Reviewed {card.relative_time_description}</Card.Meta>
            <Card.Description
              textAlign="left"
              style={{ padding: "0.6rem 0 1rem 0.5rem", marginBottom: "1rem", color: 'rgba(0,0,0,0.87)' }}
            >
              {card.text}
            </Card.Description>
          </Card.Content>
        </Responsive>

        <Responsive as={Card}
        maxWidth={760}
          style={{
            width: "500px",
            padding: "0rem 0 1rem 0",
            boxShadow: "none"
          }}
          centered
        >
          <Card.Content>
            <Image
              as="a"
              href={card.author_url}
              target="_blank"
              size="mini"
              src={card.profile_photo_url}
              style={{ marginBottom: "1rem" }}
            />
            <Card.Header>
              <a href={card.author_url} target="_blank" style={{color: '#e53935', fontSize: '1.2rem'}}>
                {card.author_name}
              </a>
            </Card.Header>
            <Card.Meta>Reviewed {card.relative_time_description}</Card.Meta>
            <Card.Description
              textAlign="left"
              style={{ padding: "0.6rem 0.8rem 1rem 1.1rem", marginBottom: "1rem", color: 'rgba(0,0,0,0.87)' }}
            >
              {card.text}
            </Card.Description>
          </Card.Content>
        </Responsive>
        </div>
      ));

    return (
      <div>
        <Responsive as={Segment} minWidth={761} style={{ padding: "6rem 0", margin: "0", borderStyle: 'none',  borderRadius: '0' }}>
          <Grid verticalAlign="middle" centered>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <Header as="h1" style={{ fontSize: "2em" }}>
                  Customer Reviews
                </Header>
                <Slider {...settings}>
                  {cardReview}
                </Slider>
                <p id="map" style={{ display: "none" }}></p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>

        <Responsive as={Segment} maxWidth={760} style={{ padding: "2.5rem 0", margin: "0", borderStyle: 'none', borderRadius: '0' }}>
          <Grid verticalAlign="middle" centered>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <Header as="h1" style={{ fontSize: "1.5rem" }}>
                  Customer Reviews
                </Header>
                <Slider {...settings}>
                  {cardReview}
                </Slider>
                <p id="map" style={{ display: "none" }}></p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
      </div>
    );
  }
}

export default Review;