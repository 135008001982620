import PropTypes from "prop-types";
import React, { Component } from "react";

import {
  Button,
  Container,
  Header,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility
} from "semantic-ui-react";

import { Link, animateScroll as scroll } from "react-scroll";

import Headings from "./Headings";
import Service from "./Service";
import Location from "./Location";
import GoogleMap from "./GoogleMap";
import Review from "./Review";
import ContactHour from "./ContactHour";
import Footer from "./Footer";

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as="h1"
      inverted
      style={{
        fontSize: mobile ? "2em" : "4em",
        fontWeight: "normal",
        marginBottom: mobile ? "0.5em" : "0",
        marginTop: mobile ? "2.5em" : "3em",
        fontFamily: "'Righteous', 'cursive'"
      }}
    >
      TACH Automotive{" "}
      <span style={{ fontSize: mobile ? "3rem" : "6rem" }}>
        <Icon name="wrench" />
      </span>
    </Header>
    <Header
      as="h2"
      content='"Quality work at a fair price."'
      inverted
      style={{
        fontSize: mobile ? "1.2em" : "1.7em",
        fontStyle: "italic",
        fontWeight: "normal",
        marginTop: mobile ? "0.5em" : "1em",
        marginBottom: mobile ? "0.5em" : "1em"
      }}
    />
    <Link to="service" spy={true} smooth={true} offset={-70} duration={500}>
      <Button animated color="red" size={mobile ? "mini" : "massive"}>
        <Button.Content visible>More Info</Button.Content>
        <Button.Content hidden>
          <Icon name="angle double right" />
        </Button.Content>
      </Button>
    </Link>
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign="center"
            style={{
              minHeight: "100vh",
              padding: "1em 0em",
              backgroundColor: "#1769aa"
            }}
            vertical
          >
            <Menu
              fixed={fixed ? "top" : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size="massive"
              style={{ border: "none" }}
            >
              <Container>
                <Link
                  onClick={this.scrollToTop}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={700}
                  className="navLink"
                >
                  <Menu.Item as="a" style={{ fontWeight: "bold" }}>
                    Home
                  </Menu.Item>
                </Link>
                <Link
                  to="service"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={700}
                  className="navLink"
                >
                  <Menu.Item as="a" style={{ fontWeight: "bold" }}>
                    Service
                  </Menu.Item>
                </Link>
                <Link
                  to="location"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={700}
                  className="navLink"
                >
                  <Menu.Item as="a" style={{ fontWeight: "bold" }}>
                    Location
                  </Menu.Item>
                </Link>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={700}
                  className="navLink"
                >
                  <Menu.Item as="a" style={{ fontWeight: "bold" }}>
                    Contact
                  </Menu.Item>
                </Link>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation="push"
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Link
            onClick={this.scrollToTop}
            spy={true}
            smooth={true}
            offset={-70}
            duration={700}
            style={{ cursor: "pointer" }}
          >
            <Menu.Item>Home</Menu.Item>
          </Link>
          <Link
            to="service"
            spy={true}
            smooth={true}
            offset={-70}
            duration={700}
            style={{ cursor: "pointer" }}
          >
            <Menu.Item>Service</Menu.Item>
          </Link>
          <Link
            to="location"
            spy={true}
            smooth={true}
            offset={-70}
            duration={700}
            style={{ cursor: "pointer" }}
          >
            <Menu.Item>Location</Menu.Item>
          </Link>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-70}
            duration={700}
            style={{ cursor: "pointer" }}
          >
            <Menu.Item>Contact</Menu.Item>
          </Link>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign="center"
            style={{
              minHeight: "50vh",
              padding: "1em 0em",
              backgroundColor: "#1769aa"
            }}
            vertical
          >
            <Container>
              <Menu
                inverted
                pointing
                secondary
                size="large"
                style={{ border: "none" }}
              >
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node
};

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node
};

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Headings />

    <Service />

    <Location />

    <GoogleMap />

    <ContactHour />

    <Review />

    <Footer />
  </ResponsiveContainer>
);
export default HomepageLayout;
