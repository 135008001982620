import React, { Component } from 'react';

import HomepageLayout from './components/HomepageLayout';

import './App.css';


export class App extends Component {
  render() {
    return (
      <div>
        <HomepageLayout/>
      </div>
    )
  }
}

export default App
